<template>
  <div>
    <!-- <pre style ="white-space: pre-line;">
      {{propText}}
    </pre> -->
    <textarea name="" id="" v-model="propText" rows="unset"></textarea>
    <b-button size="sm" variant="primary" @click="downloadAsPdf()">
      Download
    </b-button>
  </div>
</template>

<script>
import JsPDF from "jspdf"
export default {
  name: "DownLoadAsPDF",
  props: {
    propText: {
      type: String,
      default: ""
    }
  },
  methods: {
    /**
     * downloadAsPdf
     */
    downloadAsPdf () {
      const doc = new JsPDF({
        orientation: "portrait",
        unit: "in",
        format: [8.3, 11.7]
      })
      doc.setFontSize(10)
      let pageWidth = 8.3 - 2
      let lines = doc.splitTextToSize(this.propText, pageWidth)
      console.log({ lines })
      for (var i = 0; i < lines.length; i++) {
        doc.text(lines[i], 1, 0.28 * (i + 1)) // Adjust the line spacing as needed
      }
      doc.save("lor.pdf")
    }
  }
}
</script>

<style>
textarea{
  width: 100%;
  height: 71vh;
  padding: 18px;
}
</style>
