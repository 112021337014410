import request from "../../../Utils/curl"

let lor = {
  /**
   * user_profile_generate_lor
   */
  async user_profile_generate_lor (context) {
    try {
      let postData = new FormData()

      return await request.curl(context, "user_profile_generate_lor", postData)
        .then(async response => {
          return response
        })
    }
    catch (err) {
      console.error("Exception occurred at user_profile_generate_lor() and Exception:", err.message)
    }
  }
}
export { lor }
