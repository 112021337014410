/*eslint-disable */
<template>
    <b-container fluid class="p-0">
      <b-row>
        <b-col lg="12" class="p-0">
          <iq-card class="mb-2">
              <b-form >
                <div>
                  <div class="col-md-12 mb-3" >
                    <label for="validation_website" >Website</label>
                    <input v-model="vmSocialLinkFormData.social_links.website" type="text" class="form-control" required />
                  </div>
                  <div class="col-md-12 mb-3">
                    <label for="validation_website" >Facebook</label>
                    <input v-model="vmSocialLinkFormData.social_links.facebook" type="text" class="form-control" required />
                  </div>
                  <div class="col-md-12 mb-3">
                    <label for="validation_website" >Twitter</label>
                    <input v-model="vmSocialLinkFormData.social_links.twitter" type="text" class="form-control" required />
                  </div>
                  <div class="col-md-12 mb-3">
                    <label for="validation_website" >Instagram</label>
                    <input v-model="vmSocialLinkFormData.social_links.instagram" type="text" class="form-control" required />
                  </div>
                  <div class="col-md-12 mb-3">
                    <label for="validation_website" >Youtube</label>
                    <input v-model="vmSocialLinkFormData.social_links.youtube" type="text" class="form-control" required />
                  </div>
                  <div class="col-md-12 mb-3">
                    <label for="validation_website" >Linkedin</label>
                    <input id="" v-model="vmSocialLinkFormData.social_links.linkedin" type="text" class="form-control" required />
                  </div>
                </div>
                <button type="button" class="btn-primary border-0 px-3 py-2 rounded"  @click="socialLinksAdd()" >
                  <span >Save</span>
                </button>
              </b-form>
              <b-toast v-model="showToast" name="Toast" :variant="toastVariant" :title="toastTitle">
                <div v-html="toastMsg"></div>
              </b-toast>
          </iq-card>
        </b-col>
      </b-row>
    </b-container>
</template>

<script>
import ApiResponse from "../Utils/apiResponse"
import { SocialLinks } from "../FackApi/api/socialLink"

export default {
  name: "websiteAndLinks",
  props: {
    propOpenedInModal: {
      type: Boolean,
      default: false
    },
    propSocialLinks: {
      type: String,
      default: ""
    },
    propModuleId: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      vmSocialLinkFormData: Object.assign({}, this.initSocialLinks()),
      toastTitle: "",
      toastMsg: "",
      toastVariant: "",
      showToast: false
    }
  },
  mounted () {
    if (this.propOpenedInModal) {
      this.showModal = true
    }
    if (this.propSocialLinks) {
      let social = JSON.parse(this.propSocialLinks)
      this.vmSocialLinkFormData = {
        social_links: social,
        module_id: this.$route.params.user_id,
        module_name: "USER"
      }
      console.log(social)
    }
  },
  methods: {
    /** */
    initSocialLinks () {
      return {
        module_id: this.$route.params.user_id ? this.$route.params.user_id : this.propModuleId,
        module_name: "USER",
        social_links: {
          website: "",
          facebook: "",
          twitter: "",
          instagram: "",
          youtube: "",
          linkedin: ""
        }
      }
    },
    /**
     * socialLinksAdd
     */
    async socialLinksAdd () {
      try {
        console.log("TTT", this.vmSocialLinkFormData.social_links)
        const isValid = Object.values(this.vmSocialLinkFormData.social_links).find(e => e != "")
        console.log("GGG", isValid)
        if (!isValid) {
          console.log("TTTE", this)
          this.toastMsg = "Form Invalid"
          this.toastVariant = "danger"
          this.showToast = true
          this.toastTitle = "Error"
          return
        }
        else {
          console.log("Failed")
        }
        if (!this.propSocialLinks) {
          const socialLinksAddResp = await SocialLinks.socialLinkAdd(this, this.vmSocialLinkFormData)
          this.toastTitle = "Response"
          await ApiResponse.responseMessageDisplay(this, socialLinksAddResp)
          if (socialLinksAddResp && !socialLinksAddResp.resp_status) {
            return false
          }
          this.vmSocialLinkFormData = Object.assign({}, this.initSocialLinks())
          this.$emit("emitcloseWebsite_Links")
        }
        else {
          const socialLinksEditResp = await SocialLinks.socialLinkEdit(this, this.vmSocialLinkFormData)
          if (socialLinksEditResp && !socialLinksEditResp.resp_status) {
            return false
          }
          this.$emit("emitcloseWebsite_Links")
        }
      }
      catch (err) {
        console.error("Exception occurred at socialLinksAdd() and exception", err.message)
      }
    }
  }
}
</script>
