<template>
  <div class="row">
    <div class="col-md-12">
      <div class="tab-content">
        <tab-content-item :active="propShowOnlyAbout" id="about-info" aria-labelled-by="pills-about-info">
          <div class="col-sm-12 col-lg-12 p-0">
            <div class="iq-card pt-1">
              <div class="iq-card-header d-flex justify-content-between">
                  <div class="iq-header-title">
                    <h4 class="card-title">About</h4>
                  </div>
              </div>
              <hr class="m-0"/>
              <div class="iq-card-body">
                <!-- View About Description -->
                <AboutProfile :propUserObj="propUserObj"/>
              </div>
            </div>
          </div>
        </tab-content-item>
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.socialLinkRow {
  .title {
    text-transform: capitalize;
  }
 .websiteCol {
    overflow: hidden;
    text-overflow: ellipsis;
 }
}
.educationCard {
  height: auto;
  .row {
    height: auto;
  }
}
</style>
<script>
import moment from "moment"
import Utility from "../../../../../Utils/utility"
import AboutProfile from "../../../../../components/UserProfile/AboutProfile.vue"

export default {
  name: "About",
  components: {
    AboutProfile
  },
  props: {
    propUserObj: {
      type: Object,
      default: function () {
        return {
          user_name: "",
          user_email: "",
          user_gender: "",
          user_country: "",
          user_city: "",
          user_desc: "",
          user_mobile: ""
        }
      }
    },
    propShowOnlyAbout: {
      type: Boolean,
      default: true
    },
    propShowOnlyContactDetail: {
      type: Boolean,
      default: true
    },
    propShowReadMore: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      wordCount: 0,
      wordLimit: 250,
      description: "",
      utility: Utility
    }
  },
  mounted () {
    if (this.propShowReadMore && this.propUserObj.user_desc && this.propUserObj.user_desc.length) {
      const splitDesc = this.propUserObj.user_desc.split(" ")
      this.wordCount = splitDesc.length
      this.description = splitDesc.slice(0, 24).join(" ")
    }
  },
  computed: {
    userData () {
      return this.$store.getters["User/userData"]
    }
  },
  methods: {
    /**
     * emitOpenAboutTab
     */
    emitOpenAboutTab () {
      this.$emit("emitOpenAboutTab", true)
    },
    /**
     * diffDate
     */
    diffDate (startDate, endDate) {
      return moment(endDate).diff(moment(startDate), "months") + " Months"
    }
  }
}
</script>
